import { Injectable } from '@angular/core';
import { ISR } from '../../../../../../shared/src/lib/models/index.model';
import { ICompany, ISaveCompany } from '../../../../../../shared/src/lib/@ngrx/company/company.model';
import { BaseService } from '../../../../../../shared/src/lib/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService extends BaseService {
  constructor() {
    super();
    this.baseURL = `v1/company/`;
  }
  checkRRRIsPaid = (rrr: string) => this.get<{ code: string; message: string }>(`payment/status/${rrr}`);

  fetchAll(query?: { companyCode?: string }) {
    return this.get<ISR<ICompany>>(`search`, query);
  }

  create(data: ISaveCompany) {
    return this.post<ICompany>(data);
  }

  update(data: ISaveCompany) {
    return this.put<ICompany>(data);
  }
}
