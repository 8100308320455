import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends BaseService {
  constructor() {
    super();
    this.baseURL = `v1/payment/`;
  }
  checkRRRIsPaid = (rrr: string) => this.get<{ code: string; message: string }>(`remita/status/${rrr}`);

}
